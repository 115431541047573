import React from "react";
import "./About.css";
import Header from "../components/Header";
import Gandhi from "../Images/gandhi1.jpg";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div className="about-img">
      <img src={Gandhi} alt="" className="img" />

      <div className="container1">
      <div className="btn">
        <button className="about-button"  >
          About Us
        </button>
        </div>
        <div className="btn">
        <button className="about-button" >
          Quality Policy
        </button>
        </div>
        <div className="btn">
        <button className="about-button" >
          Milestones
        </button>
        </div>
      </div>
      <div className="about-text">
        <p>
          Gandhi Automations Pvt Ltd is India’s No.1 Entrance Automation and
          Loading Bay Equipment Company. This widely recognized position has
          been achieved over years of hard work, innovation, commitment to
          quality and reliable customer service. <br /> <br />
          The comprehensive product range of Gandhi Automations includes:
        </p>
      </div>
      <div className="list">
        <ul style={{ flexDirection: "column" }}>
          <li>Loading Bay Equipment</li>
          <li>High Speed Doors</li>
          <li>Rolling Shutters</li>
          <li>Fire Rated Shutters</li>
          <li>Fire Rated Doors</li>
          <li>Gates</li>
          <li>Gates</li>
          <li>Industrial Door</li>
        </ul>
        <ul style={{ flexDirection: "column" }}>
          <li>Sliding Hangar Doors</li>
          <li>Vertical Lifting Fabric Doors</li>
          <li>Sectional Overhead Doors</li>
          <li>Commercial Doors</li>
          <li>Garage Doors</li>
          <li>Swing Doors</li>
          <li>Boom Barrier</li>
        </ul>
      </div>
      
      <div>
        <h1 style={{ justifyContent: "center", textDecoration: "underline", color: "#16DEBF" }}>About Gandhi Automations</h1>
        <hr style={{ margin: "0 auto", width: "70%", border: "3px solid gray" }}/>
      </div>
      <div className="container2">
      <div className="circle-wrapper">
        <div className="circle">
          <span className="text">25</span>
        </div>
        <span className="name">Years</span>
      </div>
      <div className="circle-wrapper">
        <div className="circle">
          <span className="text">70%</span>
        </div>
        <span className="name">Market Share</span>
      </div>
      <div className="circle-wrapper">
        <div className="circle">
          <span className="text">24*7</span>
        </div>
        <span className="name">Support</span>
      </div>
    </div>

    <div className="about-text"  style={{ marginTop: "50px"}}>
      <p>Since 1996 Gandhi Automations Pvt Ltd has held a lead position in manufacturing as well as exporting, distributing and installing Entrance Automation Systems and Loading Bay Equipment that are problem free and easy to operate.</p>
      <p>Gandhi Automations specializes in designing, manufacturing and installing customised products conforming to the highest safety standards complemented with reliability and energy efficiency.</p>
      <p>All our products are designed and manufactured with European collaboration using innovative and creative engineering technology. The product development team uses the latest software combined with technologically advanced machinery to offer our customers an excellent engineered product. To ensure superior quality and operational efficiency the company has a state of the art manufacturing and warehouse facility of 400,000 sq ft.</p>
      <p>Headquartered in Mumbai, the commercial capital of India, the company has expanded its operations, geographical reach, manufacturing capacity and customer base globally. Today our products are marketed by us and our distributors in more than 70 countries across the globe.</p>
      <p>With 500+ employees and a presence in 26 major cities of India our company is business driven and supported by an efficient customer care team that is available to our customers 24 hours a day and 7 days a week.</p>
      <p>We lay great emphasis on exceptional customer service and provide regular programmed inspections and maintenance safeguards which increases product lifetime and reduces operational cost. We have a huge team of committed and diligent engineers & technicians; many of them are trained at advance training centers in Europe for this purpose.</p>
      <p>Over the years, Gandhi Automations has encountered various challenges and opportunities and we have turned each adversity into achievement given our unshaken determination and commitment to success.</p>
      <p>In Entrance Automation & Loading Bay Equipment segment Gandhi Automations has more than 70% market share in India i.e. 3 out of every 4 Entrance Automation or Loading Bay Equipment have been sold and installed by us.</p>
      <p>Gandhi Automations Pvt Ltd is the only company in the above field with a national presence through a network of branch offices with proper back up support for installation & servicing.</p>
      <p>The company has more than 100,000 installations, is certified to An ISO 9001 : 2015, ISO 14001 : 2015 & ISO 45001 : 2018 Company quality management system by TUV Nord and all products manufactured are CE marked.</p>
    </div>
    </div>
  );
};

export default About;
