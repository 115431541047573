import React from 'react';
import "./Main.css";
import City from "../Images/City1.jpg"


const Main = () => {
  return (
    <div className="main">
    <div className="background-image">
      <img src={City} alt="" />
    </div>
  </div>
  );
}

export default Main;
