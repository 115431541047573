import React from 'react';
import "./WebsiteUnderMaintance.css";
import demo5 from "../Images/demo5.jpg"

const WebsiteUnderMaintance = () => {
  return (
    <div className="slider-container">
     <img src={demo5} alt="" className="full-size-image" />
    </div>
  );
}

export default WebsiteUnderMaintance;
