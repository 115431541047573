import React, { useRef, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const navRef = useRef();
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNavbar = () => {
    setNavOpen(!isNavOpen);
  };

  return (
    <div>
      <div className="container">
        <div className="image-container">
          <img src={logo} alt="" className="logo" />
        </div>
        <div className="content-container">
          <h3 className="heading">
            India's <span style={{ color: "red" }}>No.1</span> Entrance Automation & Loading Bay Equipment Company
          </h3>
          <div className="toggle-button" onClick={toggleNavbar}>
            {isNavOpen ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={`nav-links ${isNavOpen ? "nav-open" : ""}`}>
            <li>
              <Link to="/" onClick={toggleNavbar}>Home</Link>
            </li>
            <li>
              <Link to="/web" onClick={toggleNavbar}>Website Under Maintenance</Link>
            </li>
            <li>
              <Link to="/about" onClick={toggleNavbar}>About Us</Link>
            </li>
            <li>
              <Link to="/contact" onClick={toggleNavbar}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
