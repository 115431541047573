import "./App.css";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import Header from "./components/Header";
import WebsiteUnderMaintance from "./pages/WebsiteUnderMaintance";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Main from "./components/Main";
import Home from "./pages/Home";

function App() {
  const handleSubmit = (formData) => {
    // Handle the form data, such as sending it via email
    console.log(formData);
  };
  return (
    <div>
    
      <BrowserRouter>
       <Header/>
        <Routes>
        <Route path="/" element={<Home />} />
         <Route path="/web" element={<WebsiteUnderMaintance />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact onSubmit={handleSubmit} />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
