import React, { useState } from "react";
import "./Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faFax,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const Contact = ({ onSubmit }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [company, setCompany] = useState("");
  const [comments, setComments] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Create an object with the form data
    const formData = {
      name,
      email,
      phone,
      country,
      state,
      company,
      comments,
    };

    // Pass the form data to the onSubmit prop
    onSubmit(formData);

    // Clear the form fields
    setName("");
    setEmail("");
    setPhone("");
    setCountry("");
    setState("");
    setCompany("");
    setComments("");
  };

  return (
    <div className="main1">
      <div className="heading1">
        <h1>Corporate Office</h1>
        <div className="location-container">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
          </div>
          <p className="paragraph">
            <span style={{ color: "#16debf" }}>Visit Us</span> <br />
            <b>Gandhi Automations Pvt Ltd </b> <br /> Chavda Commercial Centre,{" "}
            <br /> New Link Rd, Malad, Ram Nagar, Malad West, Mumbai,
            Maharashtra 400064
          </p>
        </div>

        <div className="location-container">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faPhone} className="icon" />
          </div>
          <p className="paragraph">
            <span style={{ color: "#16debf" }}>Call Us</span> <br />
            +91-22-66720200 / 66720300 ( 200 lines)
          </p>
        </div>

        <div className="location-container">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faFax} className="icon" />
          </div>
          <p className="paragraph">
            <span style={{ color: "#16debf" }}>FAX</span> <br />
            +91-22-6620201
          </p>
        </div>

        <div className="location-container">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
          </div>
          <p className="paragraph">
            <span style={{ color: "#16debf" }}>For Enquiries via e-mail</span>{" "}
            <br />
            sales@geapl.co.in
          </p>
        </div>

        <div className="location-container">
          <div className="icon-wrapper">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
          </div>
          <p className="paragraph">
            <span style={{ color: "#16debf" }}>Customer Support</span> <br />
            customercare@geapl.co.in
          </p>
        </div>
      </div>

      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form">
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form">
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              id="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <div className="form">
            <label htmlFor="state">State:</label>
            <input
              type="text"
              id="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </div>
          <div className="form">
            <label htmlFor="company">Company:</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)} 
            /><br /> <br /><br />
          </div>
          <div className="form">
            <label htmlFor="comments">Comments:</label>
            <textarea
              id="comments"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            ></textarea>
          </div> <br />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
