import React from "react";
import "./Footer.css";
import { BrowserRouter as Router, Link, Route, Routes, useNavigate } from "react-router-dom";


function Footer() {
  return (
    <div className="footer">
      <h3 style={{ color: "white" }}>
        Copyright © 2023 Gandhi Automations Pvt Ltd, All Rights Reserved.
      </h3>
      <div className="footer-list">
        <ul>
          <li>Dock Levelers</li>
          <li>Dock Shelters</li>
          <li>Loading Bay Doors</li>
          <li>Motorised Gates</li>
        </ul>
        <ul>
          <li>High Speed Doors</li>
          <li>Rolling Shutters</li>
          <li>Fire Rated Shutters</li>
          <li>Fire Rated Doors</li>
        </ul>
        <ul>
          <li>Cold Storage Doors</li>
          <li>Industrial Sliding Doors</li>
          <li>Sliding Hangar Doors</li>
          <li>Vertical Lifting Fabric Doors</li>
        </ul>
        <ul>
          <li>Garage Doors</li>
          <li>Commercial Doors</li>
          <li>Boom Barriers</li>
        </ul>
      </div>
      <hr style={{ color: "white" }} />
      <div style={{ display: "flex" }}>
        <ul className="nav-links1">
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/downloads">Downloads</Link>
          </li>
          <li>
            <Link to="/career">Career</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
